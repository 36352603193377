/*
 * @Author: dongjia
 * @Date: 2021-06-28 11:46:46
 * @LastEditTime: 2021-06-29 11:03:06
 * @LastEditors: aleaner
 * @Description: 商品详情页接口
 * @FilePath: \saas-admin-vue\src\modules\wx-store\api\product\detail.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 获取商品详情
export const getDetail = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/detail',
    method: 'post',
    data,
    notCancel: true
  });
};

// 保存商品详情
export const saveDetail = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/save',
    method: 'post',
    data,
    notCancel: true
  });
};

// 获取店铺列表
export const shoplist = data => {
  return api({
    url: '/admin/wx_open/wx_store_config/all',
    method: 'post',
    data,
    notCancel: true
  });
};