<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:56:02
 * @LastEditTime: 2022-02-11 14:29:53
 * @LastEditors: aleaner
 * @Description: 微信小商店商品详情页
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\wx-store\views\product-list\detail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <el-form v-if="detailForm" :rules="rules" size="medium" :model="detailForm" label-position="right"
        label-width="85px" ref="detailForm" class="medium-form">
        <div class="modular">
          <p class="modular-title">基础信息</p>
          <div class="modular-content" style="margin-left: 11px;">
            <el-form-item label="商品分类：" prop="category_id">
              <el-select v-model="detailForm.category_id" placeholder="请选择分类">
                <el-option v-for="(item, index) in categoryArr" :key="item.id" :value="item.id"
                  :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="店铺名称：" prop="shop_id">
              <el-select v-model="detailForm.shop_id" :placeholder="!shopArr.length?'暂无店铺':'请选择店铺'"
                :disabled="!shopArr.length">
                <el-option v-for="(item, index) in shopArr" :key="item.id" :value="item.id"
                  :label="item.name"></el-option>
              </el-select>
              <div class="form-tip" v-if="!shopArr.length">
                <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
                <span>暂无店铺，点击前往 <router-link :to="{name: 'WxStoreConfig'}" target="_blank"
                    class="cursor-pointer">创建小商店
                  </router-link></span>
              </div>
            </el-form-item>
            <el-form-item label="商品编号：" prop="spu_id">
              <el-input v-model="detailForm.spu_id" placeholder="请填写商品编号"></el-input>
              <div class="form-tip">
                <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
                <span>微信小商店-商品列表中的SpuId</span>
              </div>
            </el-form-item>
            <el-form-item label="商品名称：" prop="name">
              <el-input v-model="detailForm.name" placeholder="请填写商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品价格：" prop="price">
              <el-input-number class="num-input" v-model="detailForm.price" :controls="false"
                placeholder="请填写商品展示价格">
              </el-input-number>
            </el-form-item>
            <el-form-item label="商品图片：" prop="image">
              <SingleMediaWall v-model="detailForm.image" :width="100" :height="100" :borderRadius="6">
              </SingleMediaWall>
              <el-input v-show="false" v-model="detailForm.image"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveDetail">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import SingleMediaWall from "../../../common/components/SingleMediaWall";
import { getDetail, saveDetail, shoplist } from "../../api/product/detail";
import { categoryArr } from "../../api/product/list";
export default {
  components: { FixedActionBar, SingleMediaWall },
  data() {
    return {
      loading: false,
      detailForm: {
        id: this.$route.params.id,
        category_id: "",
        shop_id: "",
        spu_id: "",
        name: "",
        price: 0,
        image: "",
      },
      categoryArr: [], // 商品分类选项
      shopArr: [], // 店铺选项列表
      rules: {
        category_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        shop_id: [{ required: true, message: "请选择店铺", trigger: "change" }],
        spu_id: [
          { required: true, message: "请填写商品编号", trigger: "blur" },
        ],
        name: [{ required: true, message: "请填写商品名称", trigger: "blur" }],
        price: [
          { required: true, message: "请填写商品价格", trigger: "blur" },
          {
            min: 0,
            message: "商品价格不小于0",
            trigger: "blur",
          },
        ],
        image: [
          { required: true, message: "请上传商品图片", trigger: "change" },
        ],
      },
    };
  },
  async created() {
    await this.getCategoryArr();
    await this.getShoplist();
    if (this.$route.params.id != 0) {
      this.getDetail();
    }
  },
  methods: {
    // 获取商品分类选项列表
    getCategoryArr() {
      categoryArr()
        .then((res) => {
          this.categoryArr = res.data.filter((el) => el.id !== -1);
        })
        .catch((err) => {});
    },
    // 获取店铺列表
    getShoplist() {
      this.loading = true;
      shoplist()
        .then((res) => {
          this.shopArr = res.data.filter((el) => el.id !== -1);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getDetail() {
      this.loading = true;
      getDetail({ id: this.detailForm.id })
        .then((res) => {
          this.detailForm = res.data;
          // getMemberConfig().then(res => {
          //   this.config2 = res.data;
          this.loading = false;
          // }).catch(err => {
          //   this.loading = false;
          // })
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveDetail() {
      this.$refs.detailForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = { ...this.detailForm };
          saveDetail(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.$router.push({ name: "WxStoreGoods" });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
a {
  text-decoration: none;
  color: #3576ff;
}

.list-form + .list-form {
  margin-top: 20px;
}

.list-form {
  position: relative;
  margin-top: 10px;
  width: 530px;
  padding: 20px;
  background-color: #f9f9f9;

  .del-btn {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 14px;
    color: #3576ff;
    z-index: 2;
  }
}

.add-btn {
  width: 530px;
  margin-top: 20px;
  border: 1px dashed #dcdfe6;

  &:hover {
    border: 1px dashed #3576ff;
    background-color: #fff;
  }
}
.num-input {
  ::v-deep.el-input__inner {
    text-align: left;
  }
}
</style>